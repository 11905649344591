import React from "react"
import { Link } from "gatsby"

import FullScreenMenu from "../components/FullScreenMenu/FullScreenMenu"
import Topbar from "../components/Topbar/Topbar"
import BlockTextBig from "../components/BlockTextBig/BlockTextBig"
import BlockFullWidthImg from "../components/BlockFullWidthImg/BlockFullWidthImg"
import BlockImageMargin from "../components/BlockImageMargin/BlockImageMargin"
import BlockVideoMargin from "../components/BlockVideoMargin/BlockVideoMargin"
import BlockLeftTitleText from "../components/BlockLeftTitleText/BlockLeftTitleText"
import BlockTextImageLeft from "../components/BlockTextImageLeft/BlockTextImageLeft"
import BlockTextImageRight from "../components/BlockTextImageRight/BlockTextImageRight"
import BlockLeftTitleTextSmall from "../components/BlockLeftTitleTextSmall"
import BlockTwoColumns from "../components/BlockTwoColumns/BlockTwoColumns"
import BlockContact from "../components/BlockContact/BlockContact"
import Footer from "../components/Footer/Footer"
import leap from "./logo leap-01.svg"
import mapa from "./mapa-01.svg"
import lat1 from "./5.png"
import lat2 from "./6.png"
import lat3 from "./7.png"
import lat4 from "./8.png"
import lat5 from "./9.png"
import lat6 from "./10.png"
import lat7 from "./11.png"
import lat8 from "./12.png"
import lat9 from "./13.png"
import leapSmall from "./14.png"
import mitch from "./me2016.jpg"
import local from "./ubicacion-01.svg"
import calendar from "./calendario-01.svg"
import chris from "./chris.jpg"
import ipad from "./15.jpg"
import logo from "./logo-smtpo-leap_Mesa de trabajo 1.svg"
import coffe from "./bolsa-cafe.png"
import button1 from "./boton-1.png"
import button2 from "./boton-2.png"
import button3 from "./boton-3.png"
import button4 from "./boton-4.png"
import local2 from "./ubicacion-02.svg"

import { useHomePage } from "../hooks/use-homePage"

const Leap = () => {
  const homeData = useHomePage()
  const leftTitleTextFirstData = {
    title: homeData.field_lefttitletext_first_title_,
    text: homeData.field_lefttitletext_first_text_,
  }
  const leftTitleTextSecondData = {
    title: homeData.field_lefttitletext_second_title,
    text: homeData.field_lefttitletext_second_text_,
  }
  const leftTitleTextThirdData = {
    title: homeData.field_lefttitletext_third_title_,
    text: homeData.field_lefttitletext_third_text_,
  }
  const twoColumnsData = {
    titleOne: homeData.field_two_columns_col_1_title_,
    textOne: homeData.field_two_columns_col_1_text_,
    titleTwo: homeData.field_two_columns_col_2_title_,
    textTwo: homeData.field_two_columns_col_2_text_,
  }
  const textImageRight = {
    title: homeData.field_text_image_right_title_,
    text: homeData.field_text_image_right_text_,
    relationships: homeData.relationships,
  }
  const textImageLeft = {
    title: homeData.field_text_image_left_title_,
    text: homeData.field_text_image_left_text_,
    relationships: homeData.relationships,
  }
  const fullWidthImgData = {
    img: homeData.relationships.field_full_width_img,
  }
  const openCalendly = () => {
    window.Calendly.initPopupWidget({ url: "https://calendly.com/smartempo" })
    return false
  }
  const openMyIntegration = () => {
    window.location = "https://www.smartempo.com/myintegration"
  }
  return (
    <div className="theContainer">
      <FullScreenMenu />
      <div id="container" className="container">
        <Topbar />
        <section className="topBlockBanner">
          <div className="topBlockBanner__logo">
            <div className="topBlockBanner__logo__img">
              <img src={logo} />
            </div>
          </div>
          <div className="topBlockBanner__titleText">
            <h2>WIN AN iPAD AIR</h2>
            <p>
              Design your very own integration using our exclusive app for LEAP.
              It only takes 5 easy steps and you will be entered into a drawing
              to win!
            </p>
          </div>
          <div className="topBlockBanner__img">
            <img src={ipad} />
          </div>
          <div className="topBlockBanner__button">
            <button onClick={() => openMyIntegration()}>
              BUILD MY INTEGRATION
            </button>
          </div>
        </section>
        <section className="blockBgTextImg">
          <div className="blockBgTextImg__text">
            <p>
              Join Smartempo and <span className="font-yellow">Workfront</span>{" "}
              customers
            </p>
            <p>at the modern work management</p>
            <p>conference of the year.</p>
          </div>
          <div className="blockBgTextImg__textCenter">
            <div className="blockBgTextImg__textCenter__item">
              <div className="blockBgTextImg__twoItems__item__img">
                <div className="item__img">
                  <img src={local} />
                </div>
                <div className="item__text">
                  <p>Hilton Anatole Hotel. Dallas, TX</p>
                </div>
              </div>
              <div className="blockBgTextImg__twoItems__item__img">
                <div className="item__img">
                  <img src={calendar} />
                </div>
                <div className="item__text">
                  <p>May 6 - 9</p>
                </div>
              </div>
            </div>
            <div className="item__text__color">
              <p>Booth: B6</p>
            </div>
            <div className="item__text__button">
              <button onClick={() => openCalendly()}>
                Schedule time with us
              </button>
            </div>
          </div>
        </section>
        <section className="blockBigTitleText">
          <div className="blockBigTitleText__text">
            <h1>Master Modern Work</h1>
            <p>
              Learn from the brightest minds in keynotes, interactive breakouts,
              and one-on-one consulting sessions. Get a sneak peek of the
              Workfront product roadmap and discover how companies like yours
              are leveraging Workfront as their operational system of record.
            </p>
          </div>
        </section>
        <section className="blockBigTitle">
          <div className="blockBigTitle__text">
            <p>
              BREAKOUT SESSION: INTEGRATION DESIGN PATTERNS USING
              <span className="font-yellow"> WORKFRONT</span> FUSION
            </p>
          </div>
        </section>
        <section className="blockTwoItems">
          <div className="blockTwoItems__left">
            <div className="blockTwoItems__left__text">
              <p>
                Interested in what you can do with Workfront Fusion? As
                certified experts, we help Agencies and Marketing connect their
                MarTech stack to Workfront to achieve a better ROI on their
                marketing spend by allowing for process automation, improved
                collaboration, visibility into projects, budget tracking, and
                more. For both business users and a technical audience, join us
                as we outline and demonstrate Smartempo’s integration design
                patterns using Fusion. Come understand the power of Fusion
                within the Enterprise.
              </p>
            </div>
            <div className="blockTwoItems__left__threeItems">
              <div className="blockTwoItems__left__threeItems__item">
                <p>Date</p>
                <p>Tuesday, May 7th</p>
              </div>
              <div className="blockTwoItems__left__threeItems__item--border">
                <p>Time</p>
                <p>2:30 PM CDT</p>
              </div>
              <div className="blockTwoItems__left__threeItems__itemImg">
                <div className="blockTwoItems__left__threeItems__itemImg__img">
                  <img src={local2} />
                </div>
                <div className="blockTwoItems__left__threeItems__itemImg__text">
                  <p>Dallas, TX</p>
                </div>
              </div>
            </div>
            <div className="blockTwoItems__left__imgs">
              <div className="blockTwoItems__left__imgs__img">
                <img src={lat1} />
              </div>
              <div className="blockTwoItems__left__imgs__img">
                <img src={lat2} />
              </div>
              <div className="blockTwoItems__left__imgs__img">
                <img src={lat3} />
              </div>
              <div className="blockTwoItems__left__imgs__img">
                <img src={lat4} />
              </div>
              <div className="blockTwoItems__left__imgs__img">
                <img src={lat5} />
              </div>
              <div className="blockTwoItems__left__imgs__img">
                <img src={lat6} />
              </div>
              <div className="blockTwoItems__left__imgs__img">
                <img src={lat7} />
              </div>
              <div className="blockTwoItems__left__imgs__img">
                <img src={lat8} />
              </div>
              <div className="blockTwoItems__left__imgs__img">
                <img src={lat9} />
              </div>
            </div>
          </div>
          <div className="blockTwoItems__right">
            <div className="blockTwoItems__right__title">
              <h1>Speakers</h1>
            </div>
            <div className="blockTwoItems__right__imgText">
              <div className="blockTwoItems__right__imgText__img">
                <img src={mitch} />
              </div>
              <div className="blockTwoItems__right__imgText__text">
                <p>Mitch Patel</p>
                <p>CEO and Co-Founder</p>
              </div>
            </div>
            <div className="blockTwoItems__right__imgText">
              <div className="blockTwoItems__right__imgText__img">
                <img src={chris} />
              </div>
              <div className="blockTwoItems__right__imgText__text">
                <p>Christian Barrero</p>
                <p>Lead Developer</p>
              </div>
            </div>
            <div className="blockTwoItems__right__logo">
              <img src={leapSmall} />
            </div>
          </div>
        </section>
        <section className="bannerCenter">
          <div className="bannerCenter__content">
            <div className="bannerCenter__content__img">
              <img src={mapa} />
            </div>
            <div className="bannerCenter__content__text">
              <p>
                Visit our booth and take home some freshly roasted specialty
                coffee direct from Colombia
              </p>
            </div>
            <div className="bannerCenter__content__img">
              <img src={coffe} />
            </div>
          </div>
        </section>
        <section className="blockTextButton">
          <div className="blockTextButton__text">
            <h1>We got some cool swag!</h1>
          </div>
          <div className="blockTextButton__buttons">
            <div className="blockTextButton__buttons__img">
              <img src={button1} />
            </div>
            <div className="blockTextButton__buttons__img">
              <img src={button2} />
            </div>
            <div className="blockTextButton__buttons__img">
              <img src={button3} />
            </div>
            <div className="blockTextButton__buttons__img">
              <img src={button4} />
            </div>
          </div>
        </section>
        <section className="blockTitleVideo">
          <div className="blockTitleVideo__title">
            <h1>Why Smartempo?</h1>
          </div>
          <div className="blockTitleVideo__video">
            <iframe
              src="https://player.vimeo.com/video/332359976"
              width="640"
              height="360"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            />
          </div>
        </section>
        <Footer />
      </div>
    </div>
  )
}

export default Leap
